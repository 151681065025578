@import '../../../shared/css/colors.scss';

.tabs-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0 0 16px 0;
  justify-content: space-between;
  flex-wrap: wrap;

  .tabs-section {
    display: flex;
    flex-direction: column;
    margin-right: 32px;
  }

  .tabs-header {
    margin-bottom: 8px;
  }

  .tabs {
    display: flex;
    flex-direction: row;
    background-color: #ffffff !important;
    border-radius: 50px;
    padding: 8px;

    .tab {
      margin-right: 8px;
      padding: 8px 16px;
      border-radius: 50px !important;
      color: $primary-main;
      cursor: pointer;
      transition: all 0.3s ease;

      &:hover {
        background-color: #e3f2fd;
      }

      &.selected {
        background-color: $primary-main;
        color: #ffffff;
        transform: scale(1.05);
      }
    }
  }

  .resources-section {
    display: flex;
    flex-direction: column;

    .resources-header {
      margin-bottom: 8px;
    }

    .resource-cards {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      .card {
        display: flex;
        align-items: center;
        position: relative;
        border-radius: 16px;
        margin-right: 8px;
        margin-bottom: 8px;
        min-width: 200px;
        background-color: #ffffff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        cursor: pointer;
        transition: transform 0.3s ease;

        &:hover {
          transform: translateY(-5px);
        }

        .card-content {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 16px;
          background-color: #ffffff;
          flex: 1;
          border-radius: 16px 0 0 16px;
          position: relative;
          z-index: 1;

          .count {
            font-size: 24px;
            font-weight: bold !important;
            color: $primary-main;
          }

          .label {
            color: #444;
          }
        }

        .card-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 55px;
          height: 100%;
          background-color: $primary-main;
          color: #ffffff !important;
          font-size: 36px;
          border-radius: 0 16px 16px 0;
        }

        .curve {
          position: absolute;
          top: 0;
          left: 50px;
          width: 100px;
          height: 100%;
          background-color: #ffffff;
          z-index: 0;
          border-radius: 0 10px 10px 0;
        }

        &.user-group {
          .card-icon {
            background-color: $primary-light;
          }
        }

        &.users {
          .card-icon {
            background-color: $secondary-main;
            color: #000;
          }
        }

        &.roles {
          .card-icon {
            background-color: $primary-main;
            color: #000;
          }
        }

        &.policies {
          .card-icon {
            background-color: $info-main;
            color: #000;
          }
        }
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    .tabs-section {
      margin-right: 0;
      margin-bottom: 16px;
    }

    .tabs {
      flex-direction: column;

      .tab {
        margin-bottom: 8px;
      }
    }

    .resources-section {
      .resource-cards {
        .card {
          flex: 1 0 45%;
          margin-right: 8px;
          margin-bottom: 8px;
        }
      }
    }
  }

  @media (max-width: 480px) {

    .tabs-section .tabs .tab,
    .resources-section .resource-cards .card {
      flex: 1 0 100%;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
}