.formContainer {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
  }
  
  .form {
    width: 100%;
  }
  
  .label {
    font-weight: bold !important;
  }
  
  .required {
    color: red;
  }
  