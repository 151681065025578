.popover {
    pointer-events: none;
    background-color: transparent;

    .MuiPopover-paper {
        background: linear-gradient(to right, #ffffff, #f9f9f9); // Gradient background
        border-radius: 8px; // Rounded corners
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.2); // Subtle shadow
        padding: 8px 16px;
        min-width: 300px; // Fixed width
        max-width: 300px; // Ensures text wraps within 300px
        border: 1px solid #ddd; // Optional border
        transition: transform 0.2s ease, opacity 0.2s ease; // Smooth animation
        word-wrap: break-word; // Forces long words to break
    }

    .text {
        font-size: 14px;
        color: #333;
        font-weight: 500;
        line-height: 1.5;
        word-wrap: break-word; // Ensures text wraps correctly
    }
}