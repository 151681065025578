@import '../../../../shared/css/colors.scss';

.container {
  border-radius: 10px;
  margin-bottom: 20px !important;
  background-color: $white-main;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  margin-top: 20px;
}

.staticHead {
  position: sticky;
  top: 0;
}

.controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  width: 100%;

  .createButton {
    display: flex;
    align-items: center;
    background-color: $primary-main;
    color: $white-main;
    border-radius: 25px;
    font-size: 0.7rem;
    padding: 9px 12px;
    text-transform: none;
    justify-content: space-between;
    gap: 10px;
    border: solid 1px $primary-main;
    margin-left: 10px;

    .MuiSvgIcon-root {
      color: $white-main;
    }

    .icon {
      background-color: $white-main;
      color: $primary-main;
      border-radius: 20px;
    }
  }

  .createButton:hover {
    background-color: $white-main;
    color: $primary-main;

    .icon {
      background-color: $primary-main;
      color: $white-main;
    }
  }
}

.search {
  width: 300px;
}

.iconButtons {
  display: flex;
  align-items: center;
  gap: 5px;
}

.tableHeader {
  background-color: $white-dark ;
  display: flex;
  border-radius: 10px;
  align-items: center;
  gap: 10px;
  padding: 15px 0px;
  margin: 0 10px;
  width: 100vw;

  .headerItem {
    font-weight: bold;
    font-size: 14px;
    // text-align: center;
  }
}

.scrollBody {
  height: 415px;
  margin-top: 10px;
  overflow-x: auto;
  width: 100%;
}

.tableRow {
  display: flex;
  justify-content: space-between !important;
  align-items: center;

  width: 100%;
  height: 100%;
  margin: 0 !important;
  background-color: $white-main !important;
  gap: 10px !important;
  padding: 0 16px 0 8px !important;

  .rowItem {
    // flex: 1;
    // min-width: 140px;
    background-color: transparent !important;
    font-size: 14px;
    margin-left: 10px;
    text-overflow: ellipsis;
  }

  .rowCheck {
    padding: 0px 10px;
  }
}

@media (max-width: 768px) {
  .controls {
    flex-direction: column;
    align-items: flex-start;
  }

  .search {
    width: 100%;
  }

  .iconButtons,
  .createButton {
    margin-top: 10px;
  }

  .sectionItem {
    flex: 1 1 100%;
  }
}

.ipTableLink {
  color: $primary-main !important;
}