@import '../../shared/css/colors.scss';
.main {
    flex-grow: 1;
    padding: 2rem;
}
.listContainer{
    margin-left: 8px !important;
    display: flex;
    flex-direction: column;
    height: 100vh;
    justify-content: space-between;
}

.selectedItem {
    background-color: $white-dark !important;
    border-top-left-radius: 50px !important;
    border-bottom-left-radius: 50px !important;
}
.selectedItem::before {
    content:  '';
    position: absolute;
    top: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    box-shadow: 5px 5px 0px 5px $white-dark !important;
    border-bottom-right-radius: 20px;
    background: transparent;
}

.selectedItem::after {
    content: '';
    position: absolute;
    bottom: -20px;
    right: 0;
    width: 20px;
    height: 20px;
    border-top-right-radius: 20px;
    box-shadow: 5px -5px 0px 5px $white-dark;
    background-color: transparent;
}


.itemButton {
    min-height: 48px;
    padding: 0 20px;
}


.logo{
    align-self: flex-start !important;
}
