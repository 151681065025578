@import '../../shared/css/colors.scss';
@import '../../shared/css/variables.scss';

.consoleContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
}

.console {
    background-color: $text-primary;
    color: $white-main;
    padding: 20px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    font-size: 14px;

    .consoleTop {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        color: green;
        padding-right: 1.5rem;
    }

    .history {
        flex: 1;
        overflow-y: auto;
        margin-bottom: 10px;

        /* Custom scrollbar styles */
        &::-webkit-scrollbar {
            width: 12px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 6px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
        }
    }

    .prompt {
        color: $white-main;
        margin-right: 5px;
    }

    .input {
        flex: 1;
        background: transparent;
        border: none;
        color: $white-main;
        outline: none;
        caret-color: $white-main;
        width: 70%;
    }
}