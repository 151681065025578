.accountContainer {
}

.tabs {
}

.columns {
  display: flex;
  gap: 24px;
}

.column {
  flex: 1;
}

.tabContainer {
  display: flex;
  justify-content: center;
}

.indicator {
  border-bottom: 2px solid #1976d2;
}

.selectedTab {
  font-weight: bold;
  color: #1976d2;
}

.tab {
  color: #555;
}
