// Primary Colors
$primary-main: #0C5E9B;
$primary-light: #00B0DF;

$info-main: #0288d1;


// Secondary Colors
$secondary-main: #FEB72F;
$secondary-light: #FFD600;

// Gray Colors
$gray-light: #D0D3D5;
$gray-main: #9A9AA9;
$gray-bg: #E4E4E4;
$gray-line : #C3D4E3;

// White Colors
$white-main: #FFFFFF;
$white-dark: #F2F3F3;

// Error Colors
$error-main: #FF0000;

// Warning Colors
$warning-main: #ffa000;

// Info Colors
$info-main: #0288d1;

// Success Colors
$success-main: #00A307;
$success-light: #EBF9F1;

// Background Colors
$background-default: #F2F3F3;
$background-paper: #FFFFFF;

// Text Colors
$text-primary: #000000;