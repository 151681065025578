$font-12: 12px;
$font-14: 14px;
$font-15: 15px;
$font-16: 16px;
$font-18: 18px;
$font-20: 20px;
$font-22: 22px;
$font-24: 24px;
$font-26: 26px;
$font-28: 28px;

$large-screen : 1536px;
$meduim-screen : 1200px;
$small-screen : 600px;