.container {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  height: 326px;
  overflow: hidden;
}

.section {
  padding: 0;
}

.title {
  font-weight: 600 !important;
  font-size: 1.2rem !important;
  margin-bottom: 10px;
}

.listHeader {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-bottom: 10px;
  gap: 15px;
}

.listHeaderTitle {
  font-weight: 600 !important;
  font-size: 0.85rem !important;
  color: #0C5E9B;
  background-color: #e0e0e0;
  padding: 5px 10px !important;
  border-radius: 35px;
  text-align: center !important;
  flex: 1 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.list {
  padding: 0;
  max-height: 200px !important;
  overflow-y: auto;
}

/* Custom scrollbar styling */
.list::-webkit-scrollbar {
  width: 6px;
  /* Set the scrollbar width */
}

.list::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
}

.list::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Color of the scrollbar thumb */
  border-radius: 10px;
  /* Roundness of the scrollbar thumb */
}

/* Optional: Hover effect for scrollbar thumb */
.list::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darker color on hover */
}

.listItem {
  display: flex;
  align-items: center;
  padding: 0;
  margin-top: -10px;
}

.connectivityContainer {
  padding-left: 20px;
}

.deviceContainer,
.connectivityContainer {
  display: flex;
  align-items: center;
  flex: 1;
}

.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

.greenDot {
  background-color: #4caf50;
}

.yellowDot {
  background-color: #ffeb3b;
}

.redDot {
  background-color: #f44336;
}

.chartSection {
  text-align: center;
}

.chartWrapper {
  position: relative;
}

.legend {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.legendItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.legendText {
  margin-left: 5px;
  color: black;
}

.deviceText {
  font-size: 1rem !important;
}

.connectivityText {
  font-size: 1rem !important;
}