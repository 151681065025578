@import './colors.scss';
@import './variables.scss';

.heading1 {
    font-weight: 600 !important;
    font-size: $font-20 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-20 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-24 !important;
    }
}

.heading2 {
    font-size: $font-14 !important;
    font-weight: 500 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-16 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-20 !important;
    }
}

.heading3 {
    font-weight: 600 !important;
    font-size: $font-18 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-16 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-20 !important;
    }
}

.heading4 {
    font-weight: 500 !important;
    font-size: $font-15 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-18 !important;
    }
}

.textCenter {
    text-align: center !important;
}

.info1 {
    font-size: $font-16 !important;
    font-weight: 500 !important;
    color: $primary-main !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-18 !important;
    }

}


.info2 {
    font-size: $font-14 !important;
    font-weight: 500 !important;
    color: $gray-main !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-12 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-20 !important;
    }
}

.info3 {
    font-size: $font-12 !important;
    font-weight: 500 !important;
    color: $primary-main !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-12 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-20 !important;
    }
}

.normal1 {
    font-size: $font-14 !important;
    font-weight: 400 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-12 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-20 !important;
    }
}

.normal2 {
    font-size: $font-16 !important;
    font-weight: 500 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-20 !important;
    }
}

.small1 {
    font-size: $font-12 !important;
    font-weight: 500 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-14 !important;
    }
}

.formLabel {
    font-size: $font-14 !important;
    font-weight: 500 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-12 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-20 !important;
    }
}

.bold1 {
    font-size: $font-16 !important;
    font-weight: 600;
}



.button1 {
    text-decoration: underline;
    font-size: $font-14 !important;
    font-weight: 500;
    border: 0px;
    background-color: transparent;
    color: $primary-main;
    cursor: pointer;

    @media (max-width: $meduim-screen) {
        font-size: $font-12 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-22 !important;
    }
}

.textWhite {
    color: $white-dark !important;
}

.textGray {
    color: $gray-main;
}

.fontCapital {
    text-transform: capitalize !important;
}

.wordWrap {
    word-wrap: break-word !important;
}