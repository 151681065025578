@import '../../shared/css/colors.scss';

.searchBox {
    border-radius: 10px !important;
    background-color: $white-main !important;
    min-width: 200px !important;
    width: 100%;
    padding: 10px !important;
    margin: 0 !important;
}

.customTextField {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .textField {
        border-radius: 50px;
    }

    &:disabled {
        color: $gray-main;
    }
}

.estaric {
    color: $error-main;
    font-weight: bolder;
}

.bdataTextField {
    width: 100%;
    background-color: $gray-light;
}


.timeLabel {
    margin-bottom: 5px;
}

.disabledInput {
    background-color: rgb(240, 240, 240) !important;
    /* Light gray background */
    pointer-events: none;
    color: red !important;
    /* Disable pointer interactions */
    opacity: 0.6;
    /* Make it look grayed out */
}