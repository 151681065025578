.blockMapContainer {
    position: relative;

    .blockMap {
        width: 100%;
        height: 300px;
    }

    .blockMapTitle {
        display: flex;
        justify-content: space-between;
    }



    .blockMapToolTip {
        position: absolute;
        width: 0;
        height: 0;
        background-color: wheat !important;
    }
}

.customTooltip {
    background-color: white !important;
    border: 2px solid #0C5E9B !important;
    color: black !important;
    font-size: 14px;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}