@import "../../../../shared/css/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 10px;
}

.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 16px;

  .textContainer {
    display: flex;
    flex-direction: column;

    .header {
      margin-bottom: 8px;
    }

    .subheaderContainer {
      .subheader {
        color: #777;
      }
    }
  }

  .controls {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 50%;

    .iconButtons {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-right: 16px;

      .iconBtn {
        border-radius: 10px !important;
        background-color: $white-dark !important;
      }

      .iconBtn:hover {
        background-color: $primary-main !important;
        color: $white-main !important;

        svg {
          fill: white !important;

          path {
            fill: white;
          }
        }
      }

      .iconButton {
        margin-right: 8px;
        background-color: #f5f6f8;
        border-radius: 8px;
        padding: 6px;

        &.selected {
          background-color: #0C5E9B;

          .MuiSvgIcon-root {
            color: #fff;
          }
        }
      }

      .iconButton:hover {
        background-color: #0C5E9B;
      }

      .MuiSvgIcon-root {
        color: #0C5E9B;
      }
    }

    .actionButtons {
      display: flex;
      align-items: center;
      gap: 10px;

      .actionButton {
        margin-right: 8px;
        display: flex;
        align-items: center;
        background-color: #f5f6f8;
        border-radius: 20px;
        padding: 8px 16px;
        text-transform: none;

        .MuiSvgIcon-root {
          margin-right: 4px;
          color: #0C5E9B;
        }
      }

      .actionButton:hover {
        color: #ffffff;
        background-color: #0C5E9B;
      }

      .createButton {
        display: flex;
        align-items: center;
        background-color: #0C5E9B;
        color: #fff;
        border-radius: 25px;
        font-size: 0.7rem;
        padding: 9px 12px;
        text-transform: none;
        justify-content: space-between;
        gap: 10px;
        border: solid 1px #0C5E9B;

        .MuiSvgIcon-root {
          color: #fff;
        }

        .icon {
          background-color: #ffffff;
          color: #0C5E9B;
          border-radius: 20px;
        }
      }

      .createButton:hover {
        background-color: #ffffff;
        color: #0C5E9B;

        .icon {
          background-color: #0C5E9B;
          color: #ffffff;
        }
      }
    }
  }
}

.tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  padding-left: 16px;

  .search {
    width: 60%;

    .MuiOutlinedInput-root {
      border-radius: 50px;
    }
  }

  .pagination {
    display: flex;
    align-items: center;

    .MuiTypography-root {
      margin-right: 8px;
    }

    .MuiSelect-root {
      margin-right: 8px;
    }

    .MuiIconButton-root {
      padding: 4px;
    }
  }
}

.tableContainer {
  width: 100%;
  background-color: white;

  .tableHead {
    background-color: #f2f3f3;

    .MuiTableCell-root {
      font-weight: bold;
      color: #555;
      padding-left: 24px;
    }
  }

  .tableRow {
    background-color: #ffffff;
  }

  .tableRowZebra {
    background-color: #f7f6fe;
  }
}

@media (max-width: 768px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;

    .controls {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      .iconButtons {
        margin-right: 0;
      }

      .actionButtons {
        flex-direction: row;
      }
    }
  }

  .tableControls {
    flex-direction: column;
    align-items: flex-start;

    .search {
      width: 100%;
      margin-bottom: 8px;
    }

    .pagination {
      justify-content: center;
    }
  }
}