$sideBar-close-width: 70px;
$sideBar-open-width: 270px;
$container-full-width: 100%;

.sidebarClose {
    width: $sideBar-close-width !important;
    transition: width 0.3s !important;
}

.sidebarOpen {
    width: $sideBar-open-width !important;
    transition: width 0.3s !important;
}

.mainContainerOpen {
    width: calc(#{$container-full-width} - #{$sideBar-open-width}) !important;
    transition: width 0.3s;
    height: 100%;
    padding: 20px;
}

.mainContainerClose {
    width: calc(#{$container-full-width} - #{$sideBar-close-width}) !important;
    transition: width 0.3s !important;
    height: 100%;
    padding: 20px;
}