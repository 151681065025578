@import '../css/colors.scss';
@import '../css/variables.scss';

.customButton {
    border-radius: 100px !important;
    // color: $white-main !important;
    font-weight: 500;
    font-size: $font-14 !important;
    text-wrap: wrap;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-16 !important;
    }

    text-transform: none !important;
}

.iconWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    // padding: 5px;
    width: 35px;
    height: 35px;
    background-color: $white-main !important;
    color: $primary-light;
}