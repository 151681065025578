@import '../../../shared/css/colors.scss';


.topologyCard {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-top: 15px;
}

.topologyItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.topologyHeading {
    color: $primary-main;
    font-size: 14px;
    font-weight: bolder;
}

.topologyText {
    color: $gray-main;
    font-size: 12px;
}

.topologyButton {
    width: 80px;
    font-size: 12px;
    border-radius: 100px;
    height: 23px;
    background-color: $primary-main;
    color: white;
    border: none !important;
}