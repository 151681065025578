@import '../../shared/css/colors.scss';

.tableContainer {
    width: 100%;
    // padding-top: 15px;
    display: flex !important;
    flex-direction: column !important;
    gap: 10px;
}

.dataGridContainer {
    width: 100%;
    position: relative;
}

.actionBtn {
    border-radius: 100px !important;
    background-color: $white-dark !important;
    color: $primary-main !important;
    text-transform: none !important;
    font-weight: 500 !important;
}

.deleteBtn {
    border-radius: 100px !important;
    background-color: $white-dark !important;
    color: $primary-main !important;
    text-transform: none !important;
    font-weight: 500 !important;

    .icon {
        fill: #0c5e9b;
        transition: fill 0.3s;
    }

    &:hover {
        background-color: $error-main !important;
        color: $white-main !important;

        .icon {
            fill: white !important;
        }
    }
}







//Toolbar
.iconBtn {
    border-radius: 10px !important;
    background-color: $white-dark !important;
}

.iconBtn:hover {
    background-color: $primary-main !important;
    color: $white-main !important;

    svg {
        fill: white !important;

        path {
            fill: white;
        }
    }
}

//drawer
.drawer {
    position: relative;
    margin-left: auto;
}

.drawerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0;
    height: 492px;
    overflow: hidden;

    .upper {
        height: 400px;
    }
}

.deviceInfo {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    padding: 15px 2vw;
    border-bottom: 1px solid $gray-line;

    div {
        display: flex;
        align-items: center;
        column-gap: 10px;

        div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }
    }
}

.deviceScroll {
    height: 260px;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;

    &::-webkit-scrollbar {
        width: 10px;
    }

    &::-webkit-scrollbar-thumb {
        background: $primary-main;
    }

    &::-webkit-scrollbar-thumb:hover {
        background: black;
    }

    .itemBox {
        width: 100%;
        padding: 15px 2vw;
        border-bottom: 1px solid $gray-line;
    }

    .lastItemBox {
        width: 100%;
        padding: 15px 1vw;
    }

    .itemInfo {
        display: flex;
        justify-content: space-between;
        padding: 10px 0;
        align-items: center;

        div {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
}

.accordion {
    border: none !important;
    box-shadow: none !important;
}

.accordionSummary {
    flex-direction: row-reverse !important;
    gap: 10px !important;
    border: none !important;
}

.accordionDetails {
    margin-left: 35px !important;
    padding: 0 !important;
}

.viewDetail {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 2vw;
    height: 92px;
    border-top: 1px solid $gray-line;
}

// .tableContainer {
//     display: flex !important;
//     flex-direction: column !important;
//     gap: 0.7rem !important;
//     height: 100%;
// }