@import '../../shared/css/colors.scss';
@import '../../shared/css/variables.scss';

.container {
    position: relative;
    height: 96dvh;
    margin: 0;
    width: 96dvw;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2dvh 2dvw;
}

.left,
.right {
    position: absolute;
}

.backButtonContainer {
    display: flex;
    justify-content: flex-start;
}

.left {
    z-index: 1;
    right: 60%;
    width: 35%;
    height: 96vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10vh;

    .loginBox {
        height: 70vh;
        display: flex;
        align-items: center;
    }
}

.right {
    background-color: $primary-main; // Ensure $primary-main is defined in your variables
    left: 30%;
    height: 96vh;
    width: 70%;
    border-radius: 20px;
    padding: 2rem 1rem 2rem 10rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}


.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    width: 100%;
    padding: 20px;
    border-radius: 20px !important;
    box-shadow: 0px 0px 18px 5px #0000000A !important;


    .fieldsContainer {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .btnContainer {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

}

.loginBtn {
    height: 60px !important;
    border-radius: 100px !important;
    background-color: $primary-main !important;
    width: 50% !important;
}

.loginBtn:hover {
    background-color: $info-main !important;
}

.loginBtn:disabled {
    background-color: $gray-light !important;
}

.forgotBtn {
    color: $primary-main !important;
    cursor: pointer;
}

// Keen Slider

.navigationWrapper {
    position: relative;
}

.keenSlide {
    background: grey;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    color: #fff;
    font-weight: 500;
    height: 300px;
    max-height: 100vh;
}

//singleSLide

.slideContainer {
    display: flex;
    padding: 20px;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 60vh;

    img {
        width: 326px;
        height: 279px;
    }
}


@media (max-width: $small-screen) {
    .container {
        flex-direction: column;
        height: 100%;
        width: 98vw;
        margin: 5px !important;
        margin-bottom: 2rem;
    }


    .left,
    .right {
        position: relative;
        width: 100%;
        height: 100%;
        right: 0;
        left: 0;
        padding: 1vw;
    }

    .left {
        order: 1;
        padding-bottom: 2vh;
        gap: 10px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;

        .loginBox {
            height: 100%;
        }
    }

    .right {
        order: 2;
        padding: 1rem;
        border-radius: 10px;
    }

    .formContainer {
        height: 100%;
    }
}