@import '../../shared/css/colors.scss';

.timeRangeBox {
    border: 1px solid $gray-main;
    border-radius: 6px;
    height: 35px;
    padding: 0 10px;
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;

    .timeBtn {
        width: auto !important;
        height: 23px !important;
        min-width: auto !important;
        font-size: 12px;
        padding: 5px 7px;
        white-space: nowrap;
        text-transform: none;
    }
}


.graphTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;
    border-bottom: 1px solid #E5E5EF;
}


.customLegend {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    align-items: center;

    .item {
        display: flex;
        align-items: center;
        gap: 3px;
        border: 1px solid $gray-light;
        padding: 5px;
        border-radius: 8px;

        .box {
            width: 12px;
            height: 12px;
            border-radius: 2px;
        }

        .text {
            font-size: 12px;
            color: $gray-main;
        }
    }
}