@import "../../../../shared/css/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  border-radius: 10px;
  padding: 16px;
}

.permissionsBox {
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  min-height: 50px;
  padding: 10px;
  border-radius: 3px;
  border: 1px solid rgba(0, 0, 0, 0.23);

  .permissionChip {
    background-color: $white-main !important;
    border: 1px solid $primary-main !important;
    color: $primary-main !important;
    margin: 2px;
    padding: 1px;
  }
}

.permissionContainer {
  height: 100%;
  max-height: 300px;
  overflow-y: auto;
  padding: 10px;

  .permissionTitle {
    color: $primary-main;
  }

  .permissionSub {
    color: #868484;
  }


  .checkBox {


    &:checked {
      background-color: $primary-light;
    }
  }
}

.placeHolder {
  color: rgba(0, 0, 0, 0.6);
}


.headerContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;

  .header {
    font-weight: bold;
    font-size: 18px;
    margin-right: 8px;

    .badge {
      background-color: #0c5e9b;
      color: #fff;
      padding: 4px 8px;
      border-radius: 12px;
      font-size: 12px;
      margin-left: 8px;
    }
  }

  .subheader {
    color: #777;
    font-size: 14px;
    margin-right: auto;
    margin-left: 16px;
  }

  .controls {
    display: flex;
    align-items: center;
    gap: 10px;

    .actionButton {
      margin-right: 8px;
      display: flex;
      align-items: center;
      background-color: #f5f6f8;
      border-radius: 20px;
      padding: 8px 16px;
      text-transform: none;

      .MuiSvgIcon-root {
        margin-right: 4px;
        color: #0c5e9b;
      }
    }

    .actionButton:hover {
      color: #ffffff;
      background-color: #0c5e9b;
    }

    .iconButtons {
      display: flex;
      align-items: center;
      gap: 5px;
      margin-right: 16px;

      .iconBtn {
        border-radius: 10px !important;
        background-color: $white-dark !important;
      }

      .iconBtn:hover {
        background-color: $primary-main !important;
        color: $white-main !important;

        svg {
          fill: white !important;

          path {
            fill: white;
          }
        }
      }
    }

    .iconButton {
      margin-right: 8px;
      background-color: #f5f6f8;
      border-radius: 8px;
      padding: 6px;

      .MuiSvgIcon-root {
        color: #0c5e9b;
      }
    }

    .actionButton {
      margin-right: 8px;
      display: flex;
      align-items: center;
      background-color: #f5f6f8;
      border-radius: 20px;
      padding: 8px 16px;
      text-transform: none;

      .MuiSvgIcon-root {
        margin-right: 4px;
        color: #0c5e9b;
      }
    }

    .createButton {
      display: flex;
      align-items: center;
      background-color: #0c5e9b;
      color: #fff;
      border-radius: 25px;
      font-size: 0.7rem;
      padding: 9px 12px;
      text-transform: none;
      justify-content: space-between;
      gap: 10px;
      border: solid 1px #0c5e9b;

      .MuiSvgIcon-root {
        color: #fff;
      }

      .icon {
        background-color: #ffffff;
        color: #0c5e9b;
        border-radius: 20px;
      }
    }

    .createButton:hover {
      background-color: #ffffff;
      color: #0c5e9b;

      .icon {
        background-color: #0c5e9b;
        color: #ffffff;
      }
    }
  }
}

.tableControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;

  .search {
    width: 60%;

    .MuiOutlinedInput-root {
      border-radius: 50px;
    }
  }
}

.tableContainer {
  width: 100%;
  background-color: white;
  border: solid 1px red;


  .tableHead {
    background-color: #f2f3f3;

    .MuiTableCell-root {
      font-weight: bold;
      color: #555;
      padding-left: 24px;
    }
  }

  .tableRow {
    background-color: #ffffff;
  }

  .tableRowZebra {
    background-color: #f7f6fe;
  }
}

.dialogHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;

  .dialogSearch {
    width: 60%;

    .MuiOutlinedInput-root {
      border-radius: 50px;
    }
  }

  .dialogCreateButton {
    display: flex;
    align-items: center;
    background-color: #0c5e9b;
    color: #fff;
    border-radius: 20px;
    padding: 8px 16px;
    text-transform: none;

    .MuiSvgIcon-root {
      margin-right: 4px;
      color: #fff;
    }
  }
}

@media (max-width: 768px) {
  .headerContainer {
    flex-direction: column;
    align-items: flex-start;

    .controls {
      width: 100%;
      flex-direction: row;
      justify-content: space-between;

      .actionButtons {
        flex-direction: row;
      }
    }
  }

  .tableControls {
    flex-direction: column;
    align-items: flex-start;

    .search {
      width: 100%;
      margin-bottom: 8px;
    }

    .pagination {
      justify-content: center;
    }
  }
}

.dialogTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.closeButton {
  position: absolute;
  color: #ff0000 !important;
  background-color: #fff2f4 !important;
}

.divider {
  border: none !important;
  height: 2px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(211, 211, 211, 1) 50%, rgba(0, 0, 0, 0) 100%);
  margin-bottom: -10px !important;
}

.addButton {
  background-color: #0c5e9b !important;
  color: white !important;
  border-radius: 20px !important;
  width: 150px !important;
  margin-right: 17px !important;
  margin-top: -10px !important;
  border: solid 1px $primary-main !important;

  &:hover {
    background-color: #ffffff !important;
    color: #0c5e9b !important;
    font-weight: bold !important;
  }
}

.error {
  color: red;
  font-size: 0.8rem;
}