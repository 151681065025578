.formContainer {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  flex-direction: column;
}

.label {
  font-weight: bold !important;
}

.required {
  color: red;
}

.googleLabel {
  display: flex;
  align-items: center;
}

.googleIcon {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.switchLabel {
  justify-content: space-between;
}

.checkboxLabel {
  margin-top: 20px;
}

.section {
  margin-top: 20px;
}

.deleteInfo {
  margin-bottom: 10px;
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.halfRoundButton {
  border-radius: 20px !important;
}

.saveButton {
  background: #0c5e9b !important;
}

.logoutButton {
  color: #000;
  border-color: #ccc;
  background: #f2f3f3 !important;
}

.deleteButton {
  background: #f04444 !important;
}

.dialogPaper {
  padding: 50px;
  text-align: center;
  border-radius: 15px;
  max-width: 400px;
  margin: auto;
}

.dialogContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dialogImage {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
}

.dialogTitle {
  margin-top: 10px;
  font-weight: bold;
}

.dialogText {
  margin-top: 5px;
  margin-bottom: 20px;
}

.dialogActions {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: space-between;
}

.cancelButton {
  background: #0c5e9b !important;
  color: white !important;
  text-transform: none !important;
  border-radius: 20px !important;
  border: solid 1px #0c5e9b !important;

  &:hover {
    background: white !important;
    color: #0c5e9b !important;
  }
}

.confirmButton {
  background: #f2f3f3 !important;
  color: black !important;
  text-transform: none !important;
  border-radius: 20px !important;
  border: solid 1px #f2f3f3 !important;

  &:hover {
    background: white !important;
    border: solid 1px #0c5e9b !important;
  }
}
