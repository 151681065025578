html {
  margin: 0;
  padding: 0;
}


body {
  font-family: "Poppins";
  margin: 0;
  padding: 0;
}

pre {
  margin: 0;
  padding: 0;
  ;
}

* {
  box-sizing: border-box;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapboxgl-ctrl {
  display: none !important;
}

.mapboxgl-popup-content {
  width: 300px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.mapboxgl-popup-close-button {
  font-size: x-large;
  margin: 4px;
}

p {
  margin: 0;
}

[class^="number-slide"],
[class*=" number-slide"] {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 70vh;
  max-height: 100vh;
}


.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #D9D9D9;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #FEB72F;
}

@media print {
  body * {
    display: none !important;
  }

  #printContainer {
    display: block !important;
  }
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

@supports (-ms-ime-align: auto) {
  .password-field .eye-icon {
    display: none;
  }
}

.rs-picker-popup {
  z-index: 70000 !important;
}

.rs-picker-default {
  padding-top: 10px !important;
}

.rs-picker-toggle.rs-btn {
  font-size: 1rem !important;
  padding: 18px !important;
}

.rs-search-box-input {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 1rem !important;
}

.rs-checkbox-label {
  font-size: 1.1rem !important;
}

.rs-picker-toggle .rs-picker-toggle-indicator .rs-icon {
  width: 2em !important;
  height: 2em !important;
  margin-top: 10px !important;
}

.rs-check-tree-view {
  max-height: 120px !important;
}


.MuiTableContainer-root {
  height: 450px;
  padding: 20px;
}

.MuiTableHead-root {
  background-color: #F2F3F3;
}

.rs-stack-item {
  width: 4%;
}

.rs-picker-value-item {
  color: #0C5E9B;
}

.rs-picker-value-count {
  background-color: #0C5E9B !important;
}

.rs-checkbox-checked {
  color: #0C5E9B !important;
}

.rs-checkbox-label {
  color: #0C5E9B !important;
}


.rs-checkbox-control input[type="checkbox"]:checked+.rs-checkbox-inner {
  background-color: #0C5E9B !important;
}

.rs-checkbox-control .rs-checkbox-inner::before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  background-color: #eee;
  border-radius: 3px;
  vertical-align: middle;
}

.rs-checkbox-control input[type="checkbox"]:checked+.rs-checkbox-inner::before {
  background-color: #0C5E9B;
}