@import "../../shared/css/colors.scss";
@import "../../shared/css/variables.scss";
//nav cards

.cardContainer {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;

  .dashCard {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 10px;
    width: 140px;

    .bodyText {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 125px;
    }
  }
}

.eventCard {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  padding: 10px;
  min-width: 437px;
}

//DetailChart

.detailContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: 270px;
  position: relative;
}

.detailContainer1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  height: 270px;
  position: relative;

  @media (max-width: 1500px) {
    margin-left: 10%;
  }

  @media (max-width: 1400px) {
    margin-left: 35%;
  }

  @media (max-width: 1300px) {
    margin-left: 50%;
  }

  @media (max-width: 1200px) {
    margin-left: 50%;
  }

  @media (max-width: 1100px) {
    margin-left: 55%;
  }
  @media (max-width: 1000px) {
    margin-left: 57%;
  }
  @media (max-width: 900px) {
    margin-left: 0%;
  }
}

.detailItem {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  div {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}

.chartContainer {
  position: relative;
  height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;

  .chartBadge {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    .badge {
      padding: 5px;
      text-align: center;
      background-color: $white-dark;
      width: 60px;
      border-radius: 15px;
      font-size: $font-14;
    }
  }
}

//Sysytem Info

.systemCardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  height: 280px;
  overflow-y: auto;
}

.infoCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $primary-main;
  border-radius: 12px;
  padding: 0 10px;
  height: 100px;
  gap: 10px;
  color: $primary-main;

  @media (max-width: $small-screen) {
    justify-content: space-between;
  }

  .right {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;
    justify-content: center;
    width: 100%;
  }

  .systemInfoBadge {
    background-color: $white-dark;
    text-align: center;
    border-radius: 100px;
    padding: 5px 10px;
    color: $primary-main;
    min-width: 66px;
  }
}
