@import '../../css/colors.scss';
@import '../../css/variables.scss';

.toolBarContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 10px;

    .left {
        width: 430px;
    }

    .right {
        display: flex;
        gap: 10px;
        align-items: center;

        div {
            display: flex;
            justify-content: center;
            gap: 10px;
            align-items: center;
        }
    }

    @media (max-width: $small-screen) {
        justify-content: center;
        flex-direction: column;
    }

    @media (min-width: $large-screen) {
        justify-content: space-between;
    }
}

.noData {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 350px;
    color: $gray-main;
    gap: 10px;

}