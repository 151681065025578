.notificationContainer {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  height: 100%;
}

.label {
  font-weight: bold !important;
}

.checkboxContainer {
  display: flex;
  flex-direction: column;
}

.checkboxContainer .MuiFormControlLabel-root {
  margin-left: 0;
  margin-right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
