@import '../../../shared/css/colors.scss';
@import '../../../shared/css/variables.scss';


.innerContainer {
    display: flex;
    gap: 15px;
}


.appCard {
    display: flex;
    width: 200px;
    height: 76px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 1px 4px 0px #00000017;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    background-color: $white-main;
    height: 100%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
}


@media (max-width: $meduim-screen) {
    .innerContainer {
        justify-content: space-evenly;
    }
}

@media (max-width: $small-screen) {
    .appCard {
        width: 200px;
    }

    .left {
        width: 150px;
    }

    .right {
        width: 50px;
    }
}