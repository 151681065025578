@import "../css/colors.scss";

.dialogTitle {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $gray-line;
  align-items: center;
  padding: 0;
  padding-bottom: 20px;

  .close {
    padding: 10px;
    background-color: #fcf1f2;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
}

.dialogueContent {
  &::-webkit-scrollbar {
    display: none;
  }
}