@import '../../shared/css/colors.scss';
@import '../../shared/css/variables.scss';

.tabContainer {
    background-color: $white-main;
    border-radius: 50px;
    display: flex;
    justify-content: center;
}

.selectedTab {
    background-color: $primary-main !important;
    border-radius: 50px !important;
    color: $white-main !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 0 10px !important;
    min-height: 41px !important;
    font-weight: 500 !important;
    font-size: $font-16 !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-18 !important;
    }
}

.tab {
    color: $text-primary !important;
    font-weight: 500 !important;
    font-size: $font-16 !important;
    margin: 5px !important;
    text-transform: none !important;
    padding: 0 10px !important;
    min-height: 41px !important;

    @media (max-width: $meduim-screen) {
        font-size: $font-14 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-18 !important;
    }
}

.indicator {
    background-color: transparent !important;
}

//Device Card
.deviceItem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border-radius: 9px;
    width: 50px;
    height: 50px;
    margin: 20px;
}

.deviceMenu {
    display: flex;
    align-items: center;
    gap: 2px;
    font-size: $font-14 !important;
    cursor: pointer;

    @media (max-width: $meduim-screen) {
        font-size: $font-12 !important;
    }

    @media (max-width: $small-screen) {
        font-size: $font-24 !important;
    }
}



//Total Alerts

.circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.alertInfoContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 3vw;
    margin-right: 3vw;
}

.alertInfoText {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 5px;
}

.statsDivider {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.mapMarker {
    width: 40px;
    height: 40px;
    border: 2px solid red;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.cardTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.map {
    border-radius: 10px;
    width: 100%;
    height: 260px;
    margin-top: 5px;
}


.cardContainer {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .item {
        width: 550px;
    }
}


//dialog

.bgBlue {
    background-color: $primary-main;
}

.messageContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.textItem {
    display: flex;
    align-items: center;
    gap: 10px;
}

.copyBtn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 10px;
}

.copyBtn:hover {
    background-color: $primary-light;
}

.row {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.commandText {
    word-break: break-all;
    text-align: justify;
}