.accountContainer {
  padding: 20px;
  background-color: #f4f4f9;
  min-height: 100vh;
}


.accountCard {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-top: 20px;
  height: 100%;
  gap: 10px;
}