@import '../../shared/css/colors.scss';

.notificationItem {
    max-width: 250px;
    text-wrap: wrap;
}

.searchBoxContainer {
    width: 306px;
}

.profileButton {
    display: flex;
    align-items: flex-start;
    text-align: center;
    cursor: pointer;
    // min-width: 205px;
}

.profileButton:hover{
    background-color: $gray-light;
    transition: all ease-in-out 0.3;
}   
.profileAvatar {
    width: 40px;
    height: 40px;
    border: 3px solid $white-main;
}

.verticleDivider {
    height: 47px !important;
    background-color:  $gray-line;
}
.notificationButton{
    width: fit-content;
    max-width: 100%;
}