// BlockChainBlockInfo.module.scss

.container {
    border-radius: 10px;
    padding: 16px;
}

.row {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.icon {
    color: #0c5e9b; // Primary blue
    margin-right: 8px;
}

.label {
    font-weight: bold;
    color: #333;
    margin-right: 4px;
}

.value {
    color: #555;
    word-break: break-word;
}