@import '../../shared/css/colors.scss';
@import '../../shared/css/variables.scss';


.appCardContainer {
    display: flex;
    flex-direction: column !important;
    justify-content: flex-start;
    gap: 10px;
}

.innerContainer {
    display: flex;
    gap: 15px;
}

.appCard {
    display: flex;
    width: 160px;
    height: 76px;
    border-radius: 12px;
    overflow: hidden;
    box-shadow: 0px 1px 4px 0px #00000017;
}

.left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 120px;
    background-color: $white-main;
    height: 100%;
    border-top-right-radius: 12px;
    border-bottom-right-radius: 12px;
}

.right {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 100%;
}


.malwareSearchContainer {
    width: 50vw;
    padding-bottom: 2rem;
}

.malwareAppCardContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .malwareAppCard {
        width: 100%;
        min-height: 100px;
        // border: 1px solid $gray-main;
        // border-radius: 5px;
        word-wrap: break-word;
        overflow-wrap: break-word;
        white-space: normal;
        word-break: break-all;

        .cardItem {
            background-color: $white-dark;
            border-bottom: 1px solid $gray-line;
            padding: 10px 20px;
            font-weight: bold;

            strong {
                color: $text-primary !important;
            }
        }
    }
}

.noMalwareCard {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 200px;
    background-color: $white-dark;
    width: 100%;
    gap: 15px;

    .noMalwareText {
        display: flex;
        flex-direction: column;
        text-align: center;
    }
}



@media (max-width: $meduim-screen) {
    .innerContainer {
        justify-content: space-evenly;
    }
}

@media (max-width: $small-screen) {
    .appCard {
        width: 200px;
    }

    .left {
        width: 150px;
    }

    .right {
        width: 50px;
    }
}