@import '../../../../shared/css/colors.scss';

.container {
  background-color: $white-main;
  border-radius: 10px;
  height: 326px;
  overflow: hidden;
}

.containerFullScreen {
  height: 85vh;
  overflow: hidden;
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.topology {
  width: 100%;
}

.tooltipTopology {
  position: absolute;
  z-index: 10;
  border-radius: 13px !important;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.topologyCard {
  display: flex !important;
  flex-direction: column !important;
  gap: 5px !important;
  background-color: #fff;
  padding: 20px !important;
  width: 300px;

  .topologyItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .topologyHeading {
    color: $primary-main;
    font-size: 14px;
    font-weight: bolder;
  }

  .topologyText {
    color: $gray-main;
    font-size: 12px;
  }

  .topologyButton {
    width: 80px;
    font-size: 12px;
    border-radius: 100px !important;
    height: 23px !important;

    &:disabled {
      color: $gray-main !important;
    }
  }
}